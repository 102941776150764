nav {
    height: 4rem;
    background: var(--bg-color);
    display: grid;
    place-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}

/* Only show on medium and small screens */
.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 6rem;
    display: block;
    /* mix-blend-mode: multiply; */
    filter: contrast(1);
}

.nav__links {
    display: flex;
    gap: 3.5rem;
}

.nav__links a {
    font-weight: bold;
    font-size: 1.2rem;
    color: #fff;
    transition: var(--transition);
    opacity: 1;
}

.nav__links a:hover {
    opacity: 1;
    /* color: var(--color-secondary); */
}

.active-nav {
    position: relative;
}

.active-nav::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    left: calc(50% - 0.6rem);
    background: var(--bg-color);
    transform: rotate(45deg);
}

/*  */
/* nav a {
    opacity: 1;
    text-decoration: none;
    position: relative;
    color: white;
}

nav a::before {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    background: black;
    top: 1.5em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transform-origin: center;
    transition: transform ease-in-out 250ms;
}

nav a:hover {
    color: #000;
}

nav a:hover::before {
    transform: scale(1, 1);
} */

/*  */
/* Media queries (Medium and small screens) */
@media screen and (max-width:768px) {
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__toggle-btn svg {
        color: var(--color-gray-100);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }

    .active-nav,
    .active-nav::after {
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }

    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }

    .nav__links li a {
        background-color: var(--bg-color);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0rem 1.5rem;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }
}